import React from 'react';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import ArrowRight from '../icons/ArrowRight';
import ArrowLeft from '../icons/ArrowLeft';

import '../../node_modules/slick-carousel/slick/slick.css';
import '../../node_modules/slick-carousel/slick/slick-theme.css';

// styles
import css from '../css/components/Slideshow.module.css';

const arrowSize = 26;

const PrevButton = ({ className, style, onClick }) => (
  <button
    className={[css.arrow, css.prev, className].join(' ')}
    style={{ ...style }}
    onClick={onClick}
    aria-label="Go to previous slide"
  >
    <ArrowLeft height={arrowSize} width={arrowSize} />
  </button>
);
const NextButton = ({ className, style, onClick }) => (
  <button
    className={[css.arrow, css.next, className].join(' ')}
    style={{ ...style }}
    onClick={onClick}
    aria-label="Go to next slide"
  >
    <ArrowRight height={arrowSize} width={arrowSize} />
  </button>
);

class Slideshow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    // slick settings
    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      autoplaySpeed: 3500,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      draggable: true,
      prevArrow: <PrevButton />,
      nextArrow: <NextButton />
    };
    const { photos } = this.props;
    return (
      <div>
        <div className={css.gallery} />
        <Slider {...settings}>
          {photos.map((photo, i) => (
            <div key={i} className={css.slide}>
              <Img fluid={photo.fluid} />
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

export default Slideshow;
