import React from 'react';

const ArrowLeft = props => (
  <svg
    height={props.height}
    width={props.width}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>ctrl left</title>
    <g
      fill="#111111"
      stroke="#111111"
      strokeLinecap="round"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
    >
      <polyline fill="none" points="16,1 8,12 16,23 " stroke="#111111" />
    </g>
  </svg>
);

export default ArrowLeft;
